import URLSearchParams from 'url-search-params'
import { TStatus } from 'types'
import { EXISTING_LANG } from '../../api/constants'

// change this variable to view local build for another brand (or change iFrame src path)
const DEFAULT_BRAND = 'xbo'

export type TiFrameParams = {
  jwt: string
  lang: string
  redirect_uri: string
  gtm: string
  brand: string
  source: string
  target: string
  status: TStatus
  error_code: string
  signature: string
  theme: string
  currency: string
  amount: string
  fromPage: string
  method: string
  showScanButton: string
}

const urlSearchParams = new URLSearchParams(window.location.search)
const brand = window.location.pathname.split('/')[1] || DEFAULT_BRAND
const language = urlSearchParams.get('lang') && EXISTING_LANG.includes(urlSearchParams.get('lang') as string)
  ? urlSearchParams.get('lang')?.toLowerCase()
  : 'en'

const iFrameParams: TiFrameParams = {
  jwt: urlSearchParams.get('jwt') ?? '',
  lang: language as string,
  redirect_uri: urlSearchParams.get('redirect_uri') ?? '',
  gtm: urlSearchParams.get('gtm') ?? '',
  brand: brand,
  target: urlSearchParams.get('target') ?? '',
  source: urlSearchParams.get('source') ?? '',
  status: urlSearchParams.get('status') as TStatus ?? '',
  error_code: urlSearchParams.get('error_code') ?? '',
  signature: urlSearchParams.get('signature') ?? '',
  theme: urlSearchParams.get('theme') ?? 'light',
  currency: urlSearchParams.get('currency') ?? 'USD',
  amount: urlSearchParams.get('amount') ?? '',
  fromPage: urlSearchParams.get('fromPage') ?? '',
  method: urlSearchParams.get('method') ?? '',
  showScanButton: urlSearchParams.get('showScanButton') ?? '',
}

export default iFrameParams
